import React,{Component} from 'react';
import { MDBInput } from 'mdbreact';
import $ from 'jquery/dist/jquery.js';
import atclogo from '../../images/loader.png';
import whoAmILogo from '../../images/quiz-icon.png';




class whoAmI extends Component{ 
   constructor(props){
   	super(props)

   	this.state = {
       resourceRef:this.props.resourceRef, 
       question:"",
       optionA:"",
       optionB:"", 
       optionC:"", 
       visual:"",
       answer:"",
       taskByteValue:""

   	}

 this.fetchQuizResource = this.fetchQuizResource.bind(this);
 this.quizResponseUpdate = this.quizResponseUpdate.bind(this);
 this.responsiveClass = this.responsiveClass.bind(this);
 this.textReader = this.textReader.bind(this);

   }

  async componentDidUpdate(prevState){  
  
 
  }


  async componentDidMount(){
    
  	this.props.taskStatus(false);
  	await this.fetchQuizResource(); 
  	console.log(this.state.userAnswer);
    this.props.quizAnswer(this.state.answer);
    this.props.byteState(this.state.taskByteValue);
    //this.responsiveClass();

  }





quizResponseUpdate(event){
    console.log('i ran')
     this.setState({ 
         userAnswer: event.target.value
     })

     this.props.quizResponse(event.target.value)
}

async fetchQuizResource(){

     const requestResource = await fetch(`https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/${this.state.resourceRef}/quizTask`, {
      method:"POST", 
      headers:{
      	"Content-Type" : "application-json"
      },
      mode:"cors",
      credentials:"include"
     })
     .then(response => response.json())
     .then(response => {console.log(response[0].answer)

          this.setState({ 
                question: response[0].question,
                optionA: response[0].optionA,
                optionB: response[0].optionB,
                optionC: response[0].optionC, 
                visual: response[0].visual,
                answer: response[0].answer,
                taskByteValue: response[0].byteValue
          })



     })
}

async textReader(){

  console.log('spoke')

  $.when(window.responsiveVoice.speak(`Riddle me this`))
  .then(window.responsiveVoice.speak(`${this.state.question}`))
  .then(window.responsiveVoice.speak(`is it ${this.state.optionA}`))
  .then(window.responsiveVoice.speak(`or ${this.state.optionB}`))
  .then(window.responsiveVoice.speak(`or ${this.state.optionC}`))
             
}


responsiveClass(){ 

    var loadWidth = '';
    var loadHeight = '';
    var deviceType = null;

    var content = document.getElementById('content');
    var alert = document.getElementById('alert');
    var alertTitle = document.getElementById('alertTitle');
    var alertBody = document.getElementById('alertBody');
    var gif = document.getElementById('gif');
    var body = document.getElementById('body');


    function mobileCheck(deviceType){
        alertTitle.innerHTML = `Use ${deviceType} in landscape mode only`;
        alertBody.innerHTML = `Revert to landscape mode for the best expereince`;
        content.style.display = 'none'; //hide content of webpage
        gif.classList.remove('d-none'); //show rotate gif
        alert.style.display = ''; //show the alert
    }




    //******retrieve width and height of device on page load******
    window.onload = (() => {
        loadWidth = body.offsetWidth;
        loadHeight = body.offsetHeight;

        //retrieve device type
        if (body.offsetWidth > body.offsetHeight && body.offsetWidth > 1024) {
            deviceType = 'desktop'; 
        } else {
            deviceType = 'mobile';
        }

        //event where user loaded page using mobile and in portrait mode
        if (deviceType == 'mobile' && body.offsetHeight > body.offsetWidth) {
            mobileCheck(deviceType);
        }
    })

   

    //***** watch for changes in dimension of the body. If true, call listen(). +added timeout() delay otherwise runs on page load and can't tell 'loadwidth' from 'newWidth'
  //  setTimeout(() => {
    //    new ResizeObserver(listen).observe(body);
    //}, 1000);
         




 //******listen to changes****** 
   function listen(){
        var newWidth = body.offsetWidth;
        var newHeight = body.offsetHeight;
        
        if (newWidth !== loadWidth || newHeight !== loadHeight) { 

                //check devicetype and show specific error message
                switch (deviceType) {
                    //when device is a desktop
                    case 'desktop':
                        alertTitle.innerHTML = `Use ${deviceType} in fullscreen mode only`;
                        alertBody.innerHTML = `Revert to original screen size for the best expereince`;
                        gif.classList.add('d-none'); //hide the rotate gif (meant for mobiles only)
                        content.style.display = 'none'; 
                        alert.style.display = '';
                        break;
                    
                        //when device is a mobile
                    case 'mobile':                        
                        if (body.offsetHeight < body.offsetWidth) {
                            //event where user changes from portrait to landscape
                            content.style.display = ''; 
                            alert.style.display = 'none'; 

                        } else {
                            //event where user goes from landscape to portrait
                            mobileCheck(deviceType);
                        }
                        break;

                }
                


        } else {  
                content.style.display = ''; //show content of webpage
                alert.style.display = 'none'; //hide the alert 

                //fixes bug where user came in portrait -> landscape -> portrait 
             //       if (deviceType == 'mobile' && body.offsetHeight > screen.width) {
              //          mobileCheck(deviceType);
                    }
        }

    }


render() {

const divStyle = {
      backgroundImage: 'url("../../images/hero-large.png")',
      WebkitTransition: 'all', // note the capital 'W' here
      msTransition: 'all' // 'ms' is the only lowercase vendor prefix
};

const optionsArray= [this.state.optionA, this.state.optionB, this.state.optionC]

const optionsList = optionsArray.map(

	options => <div className="col-md-3 text-center white-text header-font"> <MDBInput onClick={this.quizResponseUpdate} label={options} filled type="radio" name="opt" value={options} />  </div>

	)

	return(
  <div className="bottom-right-curve top-right-curve" id="editor-properties" > 
   <div className="row standard-padding-tb-3 black-background top-right-curve" id="editor-instructions"> 
             <div className="col-12 col-md-2 text-center"> 
             <figure> 
             <button onClick={this.textReader} className="azure-bg" data-toggle="tooltip" data-placement="right" title="Click for voice">
              <img src={whoAmILogo} width="60"/> 
              </button>
              <figcaption className="">
               <p className="body-font yellow-background tiny-font"> Who am i </p> 
               <p className="accent-font tiny-font white-text mobile-text"> Complete the tasks by following the instructions </p>
              </figcaption> 
             </figure>
             </div>      

             <div className="col-md-10 standard-margin-top-quiz">
              <div className="row">

               <div className="col-12"> 
                     <h3 className="header-font white-text"> Riddle </h3> 
             <p className="header-font white-text mobile-text"> {this.state.question} </p>
         

               </div>
               <div className="col-12">
               <div className="row">
     {optionsList}

     <div className="col-md-3 text-center"> 
       <button id="" onClick={this.props.validateQuiz} className="btn btn-outline-success"> Submit </button>
     </div> 
     </div>
       
   </div>
              </div>
        
            </div>

   </div> 

   <div className="row bottom-right-curve "> 


 <div className="col-12 padding-off bottom-right-curve CodeMirror2"> 
   <img className="bottom-right-curve" src={this.state.visual} width="100%" height="100%" /> 
  </div>





   </div>
 </div>

		)
}



}

export default whoAmI
import React, { Component } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import jquery from '../node_modules/jquery/dist/jquery.js'
import {useCookies} from "react-cookie";
import Nav from './components/nav'
import Login from './components/login'
window.$ = window.jQuery=jquery;

    class App extends Component{


 constructor(props) {
    super(props);

    this.state = {
   
    };
    

  
    
  }

     render() { 

        return (
       
          <div className="App">
          <Nav />
           <Login />
          
          </div>
     
        );
      }
    }

    export default App;

import React, {Component} from 'react';
import coverKids from '../../images/cover-kids.png';
import internet from '../../images/the-Internet.png';
import web from '../../images/web.png';
import web1 from '../../images/web.png';




class canvask extends Component { 

  constructor(props){
     super(props)

     this.state={

           explanationA:this.props.explanationA,
           visualA: this.props.visualA
         
     }
         
  }

  componentDidMount(){
   

  }



 componentDidUpdate(){ 
 }


  render(){
  	return(
      <div className="standard-padding-tb-skin-visual"> 
       <div className="row">
           <div className="col-1"> 
           <img src={coverKids} width="70%"/>
           </div>

           <div className="col-11 text-center">
                <h4 className="header-font"> {this.props.heading} </h4>

           </div>
           </div>

           <div className="row">

           <div className="col-12 text-center"> 
                <img width="90%" src={this.state.visualA} />  
           </div>
           <div className="col-12 text-center"> 

              <h4 className="body-font"> {this.state.explanationA} </h4>
         
       
           </div>
           </div>
              
       </div>
   
    
  
  		)
  }
}
  export default canvask
import React, {Component} from 'react';
import Nav from './nav-dashboard';
import andrew from '../images/Andrew.png';



 class progress extends Component { 
   constructor(props) { 
    super(props);

     this.state ={ 
        topics :[]
     }; 

     this.retrieveTopicList = this.retrieveTopicList.bind(this)
   }


   async componentDidMount(){ 
     
     await this.retrieveTopicList()
     console.log(this.state.topics)

   }


    async retrieveTopicList(){

    	const topicList = await fetch('https://atcbrain-7de683f08325.herokuapp.com/topicsdb',{ 
          method: "GET",
          headers:{ 
            "Content-Type" : "application/json"
          },
          mode:"cors",
          credentials:"include"
    	})
    	.then(response => response.json())
    	.then(response => {console.log(response) 
            
               this.setState({
               	topics:""
               })
    	})

    }


   render() { 

      if(!this.state.topics.length){ 
          return null;
      }

   	  let topicList = this.state.topics.map(topic =>
         
          <div className="row blue-background-light standard-margin-top">
          <div className="standard-padding-tb blue-background-light standard-round-box-curve-2 col-12 IWH"  style={{ backgroundImage: `url(${topic.visual})`, backgroundSize:"cover", backgroundPosition:"center center", backgroundRepeat:"no-repeat" }} id="navbarStyle"> 

          <div className="badge blue-background-2 white-text"> Topic </div>

          <div className="standard-round-box-curve-2 green-background col-3 text-center">
           <p className="white-text header-font"> {topic.name} </p>
            </div>

         <div className="col-3">

         <p className="body-font white-text green-background-trans standard-padding-tb standard-round-box-curve standard-padding-rl"> {topic.description} </p>

         </div>


          </div>
          </div>

            )

     return(

         <div className="container nav-clear-space">
          <div className="row blue-background-2">
          <div className="col-12 col-md-4 standard-margin-top"> 
          <img src={andrew} width="350" height="350"/>
          </div>
          <div className="col-12 col-md-8 standard-margin-top"> 
           <h1 className="accent-font white-text"> Howdy <span className="body-font white-text">{this.props.candidateFirstName}</span> </h1> 
          </div>

          </div>


          {topicList}
         </div>

     	)
     }
   }

export default progress
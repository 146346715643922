import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import MetaTags from 'react-meta-tags';
import atclogo from '../images/loader.png';
import vle from '../images/atc-tease.gif';
import c4t from '../images/c4t.png';
import Cookies from 'universal-cookie';
import cookie from 'cookiejs';
import '../App.css';



class Login extends Component{

	constructor(){
       super(); 

       this.state = {
       	fullname: "",
       	password: "",
        addModalShow:false,
        addModalShowLoading:false,
        passwordUpdateModal:false
       }

       this.submitCredentials1 = this.submitCredentials1.bind(this);
       this.passwordRequest = this.passwordRequest.bind(this);
	}


//function called to reset the state of form fields onChange

updateFullNameField = (event) => { 
      this.setState({
            fullname: event.target.value
       })
  }

updatePasswordField = (event) => { 
      this.setState({
            password: event.target.value
       })
  }



updateEmailField = (event) => { 
      this.setState({
            email: event.target.value
       })
  }


async passwordRequest(){

   this.setState({
    passwordUpdateModal:false,
    addModalShowLoading:true
         })

        const emailSubmission = await fetch('https://atcbrain-7de683f08325.herokuapp.com/passwordReset', { 
            method:"POST",
            body:JSON.stringify({
              email: this.state.email
            }),
            headers:{
              "content-type" : "application/json"
            },
            mode:"cors",
            credentials:"include"
        })
        .then(response => response.json())
        .then(response => { 

          if(!response){
          this.setState({ 

              addModalShowLoading:false,
              passwordResult: true
            })

         let  modalContent = document.getElementById('passwordReset')
         modalContent.innerHTML = "There was a challenge processing your request, please try again. if this persist, please send an email to support@andrewteachescoding.org"

          }

          if(response){
            this.setState({ 
              addModalShowLoading:false,
              passwordResult: true 
 
            })

         let  modalContent = document.getElementById('passwordReset')
         modalContent.innerHTML = "Thank you, Please check your inbox for login details"
          }
        })


}

submitCredentials1 = async event => { 

  this.setState({
    addModalShowLoading:true
  })

      event.preventDefault();
      console.log('alternate login ran')
      const requestedResponse = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/auth',{ 
      method:"POST",
      body:JSON.stringify({ 
         fullname: this.state.fullname,
         password: this.state.password
      }),
      headers:{
        "content-type": "application/json"
      }, 
      mode:"cors",
      credentials:"include"
      })
      .then(response => response.json())
      .then(response => {
       if(!response){
                this.setState({
        addModalShowLoading:false,
        addModalShow:true
        })
 
          console.log('wrong password')
         let  modalContent = document.getElementById('wrongpassword')
         modalContent.innerHTML = "wrong password. For Term of code learner's, Your password starts working from 9th of may 2020 "
         }


            if(response){
         this.setState({
        addModalShowLoading:false
        })
         console.log(document.cookie)
           document.location.replace('/dashboard/home')
           console.log(response)
         }
       
  
      })
      .catch(error => console.error('Error', error));

}

     render() { 

        return (
       
          <div className="container standard-padding-tb">

  <MetaTags>
            <title> Andrew Teaches Coding | VLE </title>
            <meta name="description" content=" Online classroom infrastructure for elementary school computer language and coding" />
            <meta property="og:title" content="Andrew Teaches Coding" />
            <meta property="og:image" content="../images/vle.png" />
  </MetaTags>


            <div className="row clear-space"> 
              <div className="col-12 standard-padding-tb text-center"> 
                 <h3 className="header-font blue-text"> Welcome to your connected book </h3>
                 <p className="body-font grey-text">  If you don’t have a username or password contact your teacher </p>
                 
                </div> 
				
				<div className="col-12 col-md-6 form-space"> 
                          <input type="text" value={this.state.fullname} onChange={this.updateFullNameField} className="form-control input-field-width mx-auto standard-round-box-curve" id="" aria-describedby="emailHelp" placeholder="fullname"/>
				
				</div>
				
				<div className="col-12 col-md-6 form-space"> 
                          <input type="password" value={this.state.password} onChange={this.updatePasswordField} className="form-control input-field-width mx-auto standard-round-box-curve" id="" aria-describedby="emailHelp" placeholder="password"/>
                      
				</div>
				
				<div className="col-12 text-center">
				  <button  onClick={this.submitCredentials1} className="btn btn-primary body-font standard-round-box-curve"> Submit </button>
                      <h6 className="renew" onClick={()=> this.setState({passwordUpdateModal:true})}> 
                           Lost password?
                         </h6> 
				</div>
				
				
	
            </div>  


            <div className="row standard-padding-tb"> 
            <div className="col-12 col-md-9 mx-auto"> 

                 <img src={vle} width="800" className="img-fluid" />   

            </div>
            </div>

            
           

      <Modal show={this.state.addModalShowLoading} animation={false} >
  
        <Modal.Body id="" className="black-background-trans white-text"> 

 <div className="row left-side-curve top-right-curve bottom-right-curve"  id=""> 

        <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background-trans" > 

          <figure className="text-center standard-padding-tb nav-clear-space-screen"> 
              <h6 className="body-font text-center white-text" id="currentTime">  </h6>
              <img className="standard-margin-top-quiz" src={atclogo} width='200' />
              <figcaption className="standard-margin-top-quiz">

              </figcaption> 
          </figure>
    

        </div>

        </div>
   
        <div id="MyLiveChatContainer"></div>

         </Modal.Body>

      </Modal>       




      <Modal show={this.state.addModalShow} animation={false} >
        <Modal.Body id="wrongpassword" className="black-background white-text"> 

         </Modal.Body>
        <Modal.Footer>
         <button id="" onClick={()=> this.setState({ addModalShow:false})} className="btn btn-danger white-text"> Try again </button>
       
        </Modal.Footer>
      </Modal> 


            <Modal show={this.state.passwordResult} animation={false} >
        <Modal.Body id="passwordReset" className="black-background white-text"> 
                            
         </Modal.Body>
        <Modal.Footer>
         <button id="" onClick={()=> this.setState({ passwordResult:false})} className="btn btn-danger white-text"> Close </button>
       
        </Modal.Footer>
      </Modal> 



      <Modal className="blue-background-light" show={this.state.passwordUpdateModal} animation={false} >
        <Modal.Body className=""> 
          
              <div className="form-group text-center standard-padding-tb">
                        <label className="body-font"> Guardian's Email </label>
                            <label className="body-font grey-text"> Please input the email address connected to your account </label>
                          <input type="email" value={this.state.email} onChange={this.updateEmailField} className="form-control input-field-width mx-auto standard-round-box-curve" id="" aria-describedby="emailHelp" placeholder="Guardians email"/>
                        </div>

                         <div className="text-center"> 
                      <button  onClick={this.passwordRequest} className="btn btn-outline-success body-font standard-round-box-curve grey-text"> Submit </button>
                     </div>

         </Modal.Body>
        <Modal.Footer>
         <button id="" onClick={()=> this.setState({passwordUpdateModal:false})} className="btn btn-danger white-text"> Close </button>
       
        </Modal.Footer>
      </Modal>       
          </div>
     
        );
      }
    }

export default Login
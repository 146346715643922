import React, {Component} from 'react'; 
import character from '../images/Andrew.png';
import aladin from '../images/aladin-dad.gif';
import minion from '../images/minion.gif';
import {submission} from './submissionConfirmationModal'
import {Modal} from 'react-bootstrap'


class taskCompletion extends Component{ 

   constructor(props){ 
      super(props)

      this.state={

         addModalShow:false 
      }
 
     this.submitAssessment = this.submitAssessment.bind(this)

   }




async componentDidMount(){ 


 

}



async submitAssessment(){

   const submission = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/response-api/v1/assessments/submit',{ 
 
    method: "POST",
    headers:{
      'content-type': "application/JSON"
    },
    mode:'cors',
    credentials:'include'   
   })
   .then(response => response.json())
   .then(response => {
    console.log(response)

      this.setState({

      	addModalShow: true
      })
    window.responsiveVoice.speak(`Great! I have recieved your assessment. See you in class!`) 
      console.log(this.state.addModalShow)
    
       //document.location.replace('/dashboard/home')
   })

}




render(){ 

     return(

<div className="bottom-right-curve top-right-curve" id="editor-properties" > 
   <div className="row standard-padding-tb-3 black-background top-right-curve" id="editor-instructions"> 
             <div className="col-12 col-md-2 text-center"> 
             <figure> 
              <img src={character} width="60"/> 
              <figcaption className="">
               <p className="body-font yellow-background tiny-font"> Submission page </p> 
               <p className="accent-font tiny-font white-text"></p>
              </figcaption> 
             </figure>
             </div>      

             <div className="col-md-10 standard-margin-top-quiz">
              <div className="row">

               <div className="col-12"> 
                      <h4 className="accent-font white-text">  Assessment Complete </h4>
                    
             
         

               </div>
               <div className="col-12">
               <div className="row">
 
     </div>
        <button id="" onClick={(this.submitAssessment)} className="btn btn-danger white-text"> Submit to teacher </button>
   </div>
              </div>
        
            </div>

   </div> 

   <div className="row bottom-right-curve "> 


 <div className="col-12 padding-off bottom-right-curve CodeMirror2"> 
   <img className="bottom-right-curve" src={aladin} width="100%" height="100%" /> 
  </div>




   <Modal show={this.state.addModalShow} animation={false}>
           <Modal.Header>
          <Modal.Title> Successful Submission </Modal.Title>
        </Modal.Header>
        <Modal.Body> Hello, Your assignment is recieved. As long as your assessment button is blue, you can come back to change your answers. Always remember to use the submit button after changing any answers. See you in class! </Modal.Body>
        <Modal.Footer>

          <button id="" onClick={()=> document.location.replace('/dashboard/home')} className="btn btn-danger white-text"> Close assessments </button>
       
        </Modal.Footer>
      </Modal>

   </div>

   
 </div>


     	)
 

}




}

export default taskCompletion;
import React, {PureComponent} from 'react';
import coverKids from '../../images/cover-kids.png';
import internet from '../../images/the-Internet.png';
import web from '../../images/web.png';
import web1 from '../../images/web.png';
import kids from '../../images/kids.jpg';




class canvasSuccess extends PureComponent { 

  constructor(props){
     super(props)
   
     this.state = {
        explanationA:this.props.explanationA, 
        heading : this.props.heading,
        explanationB: this.props.explanationB,
        explanationC: this.props.explanationC,
        visualA: this.props.visualA,
        visualB: this.props.visualB , 
        firstname: this.props.fullname
      };

  }

  componentDidMount(){

  console.log(this.state.explanationA)
  console.log(this.state.visualA)

  function wrapText(context, text, x, y, maxWidth, lineHeight) {
        var words = text.split(' ');
        var line = '';

        for(var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var metrics = context.measureText(testLine);
          var testWidth = metrics.width;
          if (testWidth > maxWidth && n > 0) {
            context.fillText(line, x, y);
            line = words[n] + ' ';
            y += lineHeight;
          }
          else {
            line = testLine;
          }
        }
        context.fillText(line, x, y);
      }

  this.props.taskStatus(true)

  console.log(this.props)
  
  const img = this.refs.kids;
  const img2 = this.refs.net; 
  const img3 = this.refs.web;
  const img4 = this.refs.web1; 
  let topic = this.props.heading;
  let explanationA = this.props.explanationA;
  let explanationB = this.props.explanationB;
  let explanationC = this.props.explanationC;

  }

  componentDidUpdate(){
  console.log(this.props)
  
  const img = this.refs.kids;
  const img2 = this.refs.net; 
  const img3 = this.refs.web;
  const img4 = this.refs.web1; 
  let topic = this.props.heading;
  let explanationA = this.props.explanationA;
  let explanationB = this.props.explanationB;
  let explanationC = this.props.explanationC;
  
  
  window.responsiveVoice.speak(`That's excellent ${this.state.firstname} , you are now ready to proceed to the next lesson`)
 

  }



  render(){
  	return(
      <div className="standard-padding-tb-skin-visual"> 

      <div className="container standard-padding-tb full-height"> 

      <div className="row">
    
    <div className="col-12 text-center"> 
        <h4 className='accent-font'> 
         You just finished learning about {this.state.heading}
        </h4>
    </div>

      <div className="col-6">
       
      <img  width="100%" src= {kids} />

        </div>

      <div className="col-6 ">

      <figure className='text-center'>

        <img class="rounded-image" width="40%" src= {this.props.avatar} />

         <figcaption className='text-center'>
         <h5 class="accent-font"> Great Job {this.props.fullname}</h5>
         </figcaption>
       
        </figure>

        <h2 class="accent-font"> You should now know the following:  </h2>
        <ol>

        <li class="accent-font">  {this.state.explanationA} </li>

        <li class="accent-font"> {this.state.explanationB} </li>

        <li class="accent-font"> {this.state.explanationC} </li>

        </ol>

        <h5 class="accent-font text-center"> Lets keep learning ! </h5>
          
        </div>

        <div className="col-12 white-background">
       
        </div>
       
  
      </div>

      
      </div>

     
   
     </div>
  		)
  }
}

export default canvasSuccess
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery/dist/jquery.js';
import atcLogo from '../images/atc-logo.png';
import avatar from '../images/avatar.png';
import horn from '../images/airhorn.mp3';
import attendanceButton from '../images/attendance-button.png';
import attendanceButtonNull from '../images/attendance-button-null.png';
import {Modal} from 'react-bootstrap';
import '../App.css';


  $(document).ready(function(){

  $('[data-toggle="tooltip"]').tooltip();   

});



class skinNav extends Component {
 
  constructor(props){
     super(props);

    this.state= {
      spidy:false,
      candidateFirstName:"", 
      candidateAvatar:"",
      candidateAssessmentStatus:"",
      candidateClass:"",
      candidateSchoolid:"",
      attendance:"",
      currentAssessmentTaskRef: "",
       currentAssessmentTopicRef: "",
       currentAssessmentRef: "", 
       lastTopicProgress: "",
       lastResourceProgress: "",

   }



       this.tokenVerification = this.tokenVerification.bind(this)
       this.retrieveCandidateDetails = this.retrieveCandidateDetails.bind(this);
       this.checkAttendanceStatus = this.checkAttendanceStatus.bind(this);
       this.markAttendance = this.markAttendance.bind(this);
       this.setAssessmentStatus = this.setAssessmentStatus.bind(this);
       this.backToCurrentProgress = this.backToCurrentProgress.bind(this);
    


    }

  
  


  async componentDidMount(){ 
  
      await this.retrieveCandidateDetails();
      await this.checkAttendanceStatus();
      this.markAttendance();

      this.setState({
        lastTopicProgress: this.props.currentTopic,
       lastResourceProgress: this.props.currentResource
      })

   }



   async backToCurrentProgress(){
    this.props.closeLibrary()
    window.responsiveVoice.speak(`You are now back to ${this.state.lastTopicProgress} `)

   }


   async tokenVerification(){ 

    const tokenCheck = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/tokenStatus',{ 
  
      method:"POST",
      headers:{ 
        "content-type" : "application/json"
       },
     mode:"cors",
     credentials:"include"
    
    })
    .then(response => response.json())
    .then(response => {
      console.log(response)
        if(!response){
          window.location.href = "/"
        }
  
  })
  
  }

async retrieveCandidateDetails(){ 

    const candidate = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/candidate',{ 
      method:"POST",
      headers:{ 
        "content-type" : "application/json"
       },
     mode:"cors",
     credentials:"include"
    
    })
    .then(response => response.json())
    .then(response => {
      console.log(response)
      
      if(!response){
        return   document.location.replace('/')
      }
      if (response.progress === true || response.progress === false){ 

  if(response.candidateAvatar){
      this.setState({
        candidateFirstName:response.candidateFirstname,
        candidateAvatar: response.candidateAvatar[0],
        candidateClass : response.class,
        candidateAssessmentStatus : response.assessmentStatus,
        candidateSchoolid : response.schoolid,
        assessmentRef:response.assessmentReference
      })
    }else{ 
      this.setState({
        candidateFirstName:response.candidateFirstname,
        candidateClass : response.class,
        candidateAssessmentStatus : response.assessmentStatus,
        candidateSchoolid : response.schoolid,
        assessmentRef:response.assessmentReference
      })


    }

    }

  })
  
}


async getAssessmentReferences() {

  const classAssessmentFilterParameters = { 
       classYear: this.state.candidateClass,
       schoolid: this.state.candidateSchoolid
  }
    
    console.log(classAssessmentFilterParameters)


const candidate = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/skins/assessmentRef',{ 

method:"POST",
headers:{ 
"content-type" : "application/json"
},
body: JSON.stringify(classAssessmentFilterParameters),
mode:"cors",
credentials:"include"

})
  .then(response => response.json())
  .then(response => { 
      console.log(response)
      this.setState({  
       currentAssessmentTaskRef: response[0].taskRef,
       currentAssessmentTopicRef: response[0].topicRef,
       currentAssessmentRef: response[0].assessmentRef
      })
  })
}

async setAssessmentStatus() { 

  console.log(this.state.candidateAssessmentStatus)

  if(!this.state.candidateAssessmentStatus){

         $("#taskButton").addClass('disabled taskButton')

         
       
  }

  if(this.state.candidateAssessmentStatus){ 

    await this.getAssessmentReferences()
    
     this.setState({ 
        assessmentLink: `/lab/${this.state.currentAssessmentTopicRef}/assessments/${this.state.currentAssessmentTaskRef}/category/${this.state.currentAssessmentRef}`
     }, ()=>{ 

         if($("#taskButton").hasClass('disabled taskButton')){
          $("#taskButton").removeClass('disabled taskButton')
         }
     })
       
  }

}


async checkAttendanceStatus(){

   const candidateStatus = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/attendance-api/v1/attendancestatus', { 
   method:"POST",
   headers:{


 "content-type" : "application/json"
       },
     mode:"cors",
     credentials:"include"

   })
   .then(response => response.json())
   .then(response => { 
    console.log(response)
       if(response){

        this.setState({ 

            attendance: true
        })

       }

   if(!response){

        this.setState({ 

            attendance: false
        })

      }


   })

}


async assessmentButtonUpdate(){ 

     $('#Xplore').click(function(){ 
       console.log("explore ran")
  
     })

}


async markAttendance(){

      console.log('attendance ran a')

      $('#attendance').click(async () => { 

        console.log('attendance ran b')

      if($('#attendance').hasClass('buttonOn')){



       let horn = new Audio('../images/airhorn.mp3')

        horn.play()
        console.log('mark attendance ran')

          const markAttendance = fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/attendance-api/v1/markAttendance', { 

        method:"POST",
        headers:{
          "content-type" : "application-json"
        },
        credentials:"include",
        mode:"cors"        
   })
   .then(response => response.json())
   .then(response => { 
      

      if(!response){
           this.setState({ 
              attendance :false,
              attedanceConfirmationModal: true
              })

             }
         })
          }




      



    })


}


render(){




     let attendanceButtonType
     let attendanceClass
    
    if (this.state.attendance === true ){

       attendanceButtonType = attendanceButton
      $('#attendance').addClass('buttonOn')

        if($("#attendance").hasClass('buttonOff')){
        $('#attendance').removeClass('buttonOff')
       }

     
    
    }else{

      attendanceButtonType = attendanceButtonNull

      $('#attendance').addClass('buttonOff')

       if($("#attendance").hasClass('buttonOn')){
        $('#attendance').removeClass('buttonOn')
       }


    }




  	return(
    
      <nav className="navbar navbar-expand navbar-light navbar-skin fixed-bottom padding-off" >
<div className="collapse navbar-collapse standard-margin-top-quiz" id="navbarSupportedContent">
  <a className="navbar-brand col-2 col-sm-1 text-center" href="/dashboard/home"><img src={atcLogo} width="75" /></a>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

<ul className="navbar-nav col-sm-2 text-right">
       <li> 
    <figure className="text-center"> 
    <figcaption> <button onClick={this.props.exploreScreen} className="btn btn-danger standard-round-box-curve tiny-font" id="Xplore"> Explore </button>  </figcaption>
     </figure> 
    </li> 

    <li> 
    <figure className="text-center"> 
    <figcaption> <button onClick={this.props.bookSelection} className="btn btn-danger standard-round-box-curve tiny-font" id="Xplore"> Books </button>  </figcaption>

     </figure> 
    </li> 
    <li>
    <figure className="text-center"> 
    <figcaption> <Link onClick={this.backToCurrentProgress} to={`/lab/${this.state.lastTopicProgress}/lessons/${this.state.lastResourceProgress}`} className="btn btn-success standard-round-box-curve tiny-font" id="Xplore"> Current Progress </Link>  </figcaption>
   
     </figure> 
    </li> 

</ul>


  <ul className="navbar-nav col-sm-3 offset-sm-6 text-right">
       <li>
   
  
        </li>
       <li> 
    <figure className="text-center"> 
     <h6 className="tiny-font body-font"> {this.props.email} </h6>
    <figcaption> <button onClick={this.props.menuScreen} className="btn btn-success standard-round-box-curve tiny-font" id="skins"> Change skin </button>  </figcaption>
     </figure> 
    </li> 
    <li className="text-right"> <img className="circular" src={this.state.candidateAvatar}  width="25" /> </li>
    <li> 
    <figure className="text-center"> 
     <h6 className="tiny-font body-font"> {this.props.email} </h6>
    <figcaption> <button className="btn btn-success standard-round-box-curve tiny-font" onClick={this.props.profileMenu}> Online fellows </button>  </figcaption>
     </figure> 
    </li> 
    <li className="text-right"> <img src={attendanceButtonType}  id="attendance" width="50" /> </li>
    
  </ul>
  </div>


   <Modal show={this.state.attedanceConfirmationModal} animation={false}>
           <Modal.Header className="green-background">
          <Modal.Title  className="white-text"> Attedance Register </Modal.Title>
        </Modal.Header>
        <Modal.Body> Attendance Marked! </Modal.Body>
        <Modal.Footer>

          <button id="" onClick={()=> this.setState({attedanceConfirmationModal:false})} className="btn btn-success white-text"> Close </button>
       
        </Modal.Footer>
 </Modal>


</nav>

  	)

  }
}



export default skinNav
import React, {Component} from 'react';
import {Route, Link} from 'react-router-dom';
import $ from 'jquery/dist/jquery.js';
import moment from 'moment';
import {ToastsContainer, ToastsStore, ToastsContainerPosition, timer} from 'react-toasts';
import Nav from './nav-dashboard-skin';
import atclogo from '../images/loader.png'
import coverKids from '../images/cover-kids.png';
import web from '../images/web.png';
import webpage from '../images/webpage.png';
import website from '../images/web-pages.png';
import editor from '../images/text-editor.png';
import html from '../images/html.png';
import quiz from '../images/quiz-icon.png';
import graded from '../images/graded.png';
import submit from '../images/submit.png';
import update from '../images/update.png'; 
import forwardSwitchButton from '../images/next.png';
import switchButtonDisabled from '../images/next-disabled.png';
import backwardSwitchButtonDisabled from '../images/back-disabled.png';
import SwitchButton from './forward-switch-button-assessments';
import BackwardSwitchButton from './backward-switch-button-assessments'
import Assessments from './learningmodules/assessments';
import vle from '../images/vle.png'
import navigation from '../images/nav.png'
import attendance from '../images/attendance.png'
import fellows from '../images/fellows.png'
import skins from '../images/skins.png'
import {Modal} from 'react-bootstrap'
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';






 class lab1 extends Component { 

   constructor(props) { 
    super(props);

    let {match: { params } } = this.props

     this.state ={ 
        height:"",
        width:"",
        skinPack:[],
        iconPack:[],
        assessmentRefLink:"",
        assessmentRef:[],
        assessmentPretty:[],
        prevTaskRef:"",
        nextTaskRef: "",
        topicRef: params.topicRef,
        candidateFirstName:"",
        codeData:"",
        responseData:"",
        activateForwardArrow:false,
        taskByteValue:"",
        candidateSkin:"",
        candidateSkinRef:"",
        candidateClass:"",
        candidateAvatar:"",
        candidateAssessmentStatus:"",
        onlineCandidates:[],
       candidateAge:"",
       candidateSchool:"",
       candidateFullname: "",
       candidateSchoolid:"",
      candidateTopicVisual: "",
      candidateTopicDescription: "",
      candidateTopicName: "", 
       onlineCandidates:[],
       currentAssessmentTaskRef: "",
       currentAssessmentTopicRef: "",
       currentAssessmentRef: "",
       assessmentLink:"",
       schoollogo:"",
       assessmentGrade:"",
       totalTopicGrade:"",
       currentTopicGrade:"",
       newAssessmentRef:"",
        addManualModalShow: true,
        bookName:"",
        assessmentRefLink:"",
        assessmentTag:"",
        
     }; 

     this.canvasHeight = this.canvasHeight.bind(this);
     this.canvasWidth = this.canvasWidth.bind(this);
    // this.timeUpdate = this.timeUpdate.bind(this);
     //this.sleepScreen = this.sleepScreen.bind(this);
     this.menuScreen = this.menuScreen.bind(this);
     this.menuClose = this.menuClose.bind(this);
     this.profileScreen = this.profileScreen.bind(this);
     this.profileClose = this.profileClose.bind(this);
     this.exploreScreen = this.exploreScreen.bind(this);
     this.exploreClose = this.exploreClose.bind(this);
   //  this.wakeScreen = this.wakeScreen.bind(this);
     this.skinMenu = this.skinMenu.bind(this);
     this.profileMenu = this.profileMenu.bind(this);
     this.skinUpdate = this.skinUpdate.bind(this);
     this.byteStateUpdate = this.byteStateUpdate.bind(this);
     this.candidateByteIncrease = this.candidateByteIncrease.bind(this);
     this.retrieveTaskDetails = this.retrieveTaskDetails.bind(this);
     this.switchSkin = this.switchSkin.bind(this);
     this.htmlStateUpdate = this.htmlStateUpdate.bind(this);
     this.progressUpdate = this.progressUpdate.bind(this);
     this.taskStatusUpdate = this.taskStatusUpdate.bind(this);
     this.retreiveResourceIconPack = this.retreiveResourceIconPack.bind(this);
     this.saveEditorResponse = this.saveEditorResponse.bind(this);
     this.quizAssessmentResponseUpdate = this.quizAssessmentResponseUpdate.bind(this);
     this.retrieveOnlineFellowsBySchool = this.retrieveOnlineFellowsBySchool.bind(this);
     this.openFullscreen = this.openFullscreen.bind(this);
     this.editStudentProfile = this.editStudentProfile.bind(this);
     this.setAssessmentStatus = this.setAssessmentStatus.bind(this);
     this.getAssessmentReferences = this.getAssessmentReferences.bind(this);
     this.resultScreen = this.resultScreen.bind(this);
     this.resultClose = this.resultClose.bind(this);
     this.bookNameUpdate = this.bookNameUpdate.bind(this);
     this.assessmentTagUpdate = this.assessmentTagUpdate.bind(this);
     this.assessmentLinkUpdate = this.assessmentLinkUpdate.bind(this);
}


 
async componentDidMount(){
//get current width & height of container
   await this.retrieveCandidateDetails();
            this.openFullscreen();
     await  this.canvasHeight();
     await  this.canvasWidth();
    // this.retreiveResourceIconPack()
     await  this.retrieveTaskDetails();
    
     await this.setAssessmentStatus();
     this.editStudentProfile();

     console.log(this.state.prevTaskRef)
}

canvasHeight() { 
    let availableSpaceHeight = document.getElementById('explanation').offsetHeight
     let  spaceHeight = availableSpaceHeight; 
       
    this.setState({
      height: spaceHeight.valueOf()
     }) 
}


canvasWidth() { 
     let availableSpaceWidth = document.getElementById('explanation').offsetWidth
     let spaceWidth = availableSpaceWidth 
    this.setState({
      width: spaceWidth.valueOf()
     }) 
  }


async shouldComponentUpdate(prevProps){

}

async componentDidUpdate(prevProps){
    const {match:{params}} = this.props
    //console.log(prevProps.match.params.resourceRef)
    //console.log(params.resourceRef)
    if (prevProps.match.params.taskRef != params.taskRef){   
       await this.retrieveTaskDetails()     
    }


    if (prevProps.match.params.topicRef != params.topicRef){          
       // await this.retreiveResourceIconPack()
    }


         //console.log(this.state.taskComplete)
         //console.log(this.state.userAnswer)

          console.log(this.state.width)
         console.log(this.state.height)
         console.log(this.state.candidateSkin)

}

async componenDidRecieveProps(prevProps){


}



async openFullscreen() {
  if (document.requestFullscreen) {
    document.requestFullscreen();
  } else if (document.mozRequestFullScreen) { /* Firefox */
    document.mozRequestFullScreen();
  } else if (document.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    document.webkitRequestFullscreen();
  } else if (document.msRequestFullscreen) { /* IE/Edge */
    document.msRequestFullscreen();
  }
}

async menuScreen(){
  let active
  let menu
  let profile
  let explore 
  let result
  
  active = document.getElementById('active-screen');
  menu =  document.getElementById('menu-screen');
  profile = document.getElementById('fellows-screen');
  explore = document.getElementById('explore-screen');
  result = document.getElementById('result-screen');
  
  $(active).fadeOut('fast',() => {
      $(menu).fadeIn('fast', () => {
        $(profile).fadeOut('fast', () => {
          $(explore).fadeOut('fast', () => { 
            $(result).fadeOut('fast')
          })
        })
      })
  })
  
  }

async menuClose(){

let active
let menu

active = document.getElementById('active-screen');
menu =  document.getElementById('menu-screen');

$(menu).fadeOut('fast',() => {
    $(active).fadeIn('fast')
})

}


async profileScreen(){ 
  console.log('i ran')
let active
let profile
let menu
let explore
let result

active = document.getElementById('active-screen');
profile =  document.getElementById('fellows-screen');
menu =  document.getElementById('menu-screen');
explore = document.getElementById('explore-screen');
result = document.getElementById('result-screen');



$(active).fadeOut('fast',() => {
    $(profile).fadeIn('fast', () => { 
       $(menu).fadeOut('fast', () => { 
        $(explore).fadeOut('fast', () => { 
               $(result).fadeOut('fast')
           })
       })
    })
})

}


async profileClose(){

let active
let profile

active = document.getElementById('active-screen');
profile =  document.getElementById('fellows-screen');

$(profile).fadeOut('fast',() => {
    $(active).fadeIn('fast')
})

}


async resultClose(){

  let explore
  let result
  
  
  explore = document.getElementById('explore-screen');
  result =  document.getElementById('result-screen');
  
  $(result).fadeOut('fast',() => {
      $(explore).fadeIn('fast')
  })
  
  }


async exploreScreen(){ 
console.log('i ran')
let active
let profile
let menu
let explore
let result

await this.retrieveCandidateDetails()
await this.setAssessmentStatus()

explore = document.getElementById('explore-screen')
active = document.getElementById('active-screen');
profile =  document.getElementById('fellows-screen');
menu =  document.getElementById('menu-screen');
result = document.getElementById('result-screen');

$(active).fadeOut('fast',() => {
    $(explore).fadeIn('fast', () => { 
      
       $(menu).fadeOut('fast', () => { 
         $(profile).fadeOut('fast', () => { 
          $(result).fadeOut('fast')
         })
       })
    })
})

}





async retrieveCandidateScore(assessmentRefName){ 

 const {match:{params}} = this.props
  const data = { 
    assessmentRef:assessmentRefName,
    topicRef: params.topicRef     
  }
 
  const skinUpdate = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/getCandidateResults', { 
    method:"POST",
    headers:{
      "content-type":"application/JSON"
    },
    body:JSON.stringify(data),
    mode:"cors",
    credentials:'include' 
  })

   .then(response => response.json())
   .then(response => {
     console.log(response)
    this.setState({ 
        assessmentGrade: response.assessmentGrade,
        totalTopicGrade: response.totalTopicGrade,
        currentTopicGrade: response.currentTopicGrade,
        newAssessmentRef: response.newAssessmentRef
    })
   })
   console.log(this.state.assessmentGrade)

   console.log(this.state.totalTopicGrade)

   console.log(this.state.newAssessmentRef)
   


}



async resultScreen(event){ 
  console.log('i ran')
let active
let profile
let menu
let explore
let result 


let assessmentReference = event.target.id


 
  
  explore = document.getElementById('explore-screen')
  active = document.getElementById('active-screen');
  profile =  document.getElementById('fellows-screen');
  menu =  document.getElementById('menu-screen');
  result =  document.getElementById('result-screen');
  
  $(explore).fadeOut('fast',() => {
      $(result).fadeIn('fast', () => { 
       this.retrieveCandidateScore(assessmentReference)
  })
}) 


}



async exploreClose(){

let active
let explore

active = document.getElementById('active-screen');
explore =  document.getElementById('explore-screen');

$(explore).fadeOut('fast',() => {
    $(active).fadeIn('fast')
})

}

async progressUpdate(){ 

 console.log('progress ran')
   const {match :{params}} = this.props;

   console.log(params.taskRef)
   console.log(params.topicRef)

   const responseReceived = await fetch(`https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/progress-update/${params.topicRef}/${params.taskRef}`, {
     method: "POST",
     headers:{
      "content-type" : "application/JSON"
     },
     mode: "cors",
     credentials:"include"
    })

   const  response = await responseReceived.json()

}


 // async timeUpdate (){
  //const currentTime = moment().format('h:mm:ss a')
  //const timeDisplayContainer = document.getElementById('currentTime')
  //timeDisplayContainer.innerHTML= currentTime
   //} 


taskStatusUpdate(status){
    console.log(status)
    this.setState({ 
     activateForwardArrow:status
    })
}


htmlStateUpdate(data){
  
    this.setState({ 
       codeData: data 
    });
}

bookNameUpdate(data){

  this.setState({ 
     bookName: data 
  });
}


assessmentTagUpdate(data){
  
  this.setState({ 
     assessmentTag: data 
  });
}

assessmentLinkUpdate(data){

  this.setState({ 
     assessmentRefLink: data 
  });
}



byteStateUpdate(taskByteValue){
  console.log(taskByteValue)
   this.setState({ 
    taskByteValue: taskByteValue
  })
}

async quizAssessmentResponseUpdate(){ 

  const {match:{params}} = this.props

  console.log('quiz assessment ran')

  
 

   

  let assessmentResponsePack = {
      response: this.state.codeData,
      tag: this.state.assessmentTag,
      taskRef: params.taskRef,
      assessmentRef: this.state.assessmentRefLink,
      bookName: this.state.bookName
    }


    console.log(assessmentResponsePack)

    //store response into db

    const storeCandidateAssessmentResponse = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/response-api/v1/saveAssessmentResponse', { 
    method:"POST",
    headers:{
     "content-type":"application/JSON"
    },
    body:JSON.stringify(assessmentResponsePack),
    mode:'cors',
    credentials:'include'
    })
    .then(response => response.json())
    .then (response =>{
      this.setState({
        activateForwardArrow: true,
      })
       ToastsStore.success(`your answer has been saved!`, 
                  2000)

       window.responsiveVoice.speak(`your answer has been saved!`) 
 

    
    }

 

     )
  

    
}



async switchSkin(){ 
    console.log(' i ran ')  

    const recieveSkinPack = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/skins',{ 
    method:"GET",
    headers:{
       
    }, 
    mode:'cors',
    credentials:'include'
    })
    .then(response => response.json())
    .then(response => { 
      this.setState({ 
         skinPack: response
      })
      console.log(this.state.skinPack)
    })
}

async skinUpdate(event){

 const ref = event.target.id

 console.log(ref)
  const data = { skinRef: ref }
  const skinUpdate = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/skins/retrieveSkinLink', { 
    method:"POST",
    headers:{
      "content-type":"application/JSON"
    },
    body:JSON.stringify(data),
    mode:"cors",
    credentials:'include' 
  })

   .then(response => response.json())
   .then(response => {
    this.setState({ 
        candidateSkin: response[0].skinLink
    })
   })

}

async skinMenu() {

 await this.menuScreen()
 await this.switchSkin()

}


async candidateByteIncrease(){

  console.log(this.state.taskByteValue)
  const data = {earnedByteValue: this.state.taskByteValue}
  const byteUpdate = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/response-api/v1/updateByteCount/increase', { 
    method:"POST",
    headers:{
      "content-type":"application/JSON"
    },
    body:JSON.stringify(data),
    mode:"cors",
    credentials:'include' 
  })

  if(!byteUpdate){
   return console.log('server did not respond with byte')
  }

  const byteUpdateResponse = byteUpdate.json()
  console.log(byteUpdateResponse)

}


async retrieveOnlineFellowsBySchool(){ 

     console.log('get candidates ran')
   const retrievedOnlineFellows = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/get-online-candidates',{ 
   method:"POST",
   headers:{
    "content-type" : "application-JSON"
   },
   mode:"cors",
   credentials:"include"
   })

   .then(response => response.json())
   .then(response => { 
      this.setState({ 
          onlineCandidates : response
      })
   })

} 


async profileMenu(){

 console.log('profile ran')
  await this.profileScreen()
  await this.retrieveOnlineFellowsBySchool()

}



async getAssessmentReferences() {

  const classAssessmentFilterParameters = { 
       classYear: this.state.candidateClass,
       schoolid: this.state.candidateSchoolid
  }
    
    console.log(classAssessmentFilterParameters)


const candidate = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/skins/assessmentRef',{ 

method:"POST",
headers:{ 
"content-type" : "application/json"
},
body: JSON.stringify(classAssessmentFilterParameters),
mode:"cors",
credentials:"include"

})
  .then(response => response.json())
  .then(response => { 
      console.log(response)
      this.setState({  
       currentAssessmentTaskRef: response[0].taskRef,
       currentAssessmentTopicRef: response[0].topicRef,
       currentAssessmentRef: response[0].assessmentRef
      })
  })
}


async setAssessmentStatus() { 
  console.log('ranning1')
  console.log(this.state.candidateAssessmentStatus)

  if(!this.state.candidateAssessmentStatus){

         $("#taskButton").addClass('disabled taskButton')

         
       
  }

  if(this.state.candidateAssessmentStatus){ 
console.log('ranning')
    await this.getAssessmentReferences()
    
     this.setState({ 
        assessmentLink: `/lab/${this.state.currentAssessmentTopicRef}/assessments/${this.state.currentAssessmentTaskRef}/category/${this.state.currentAssessmentRef}`
     }, ()=>{ 

         if($("#taskButton").hasClass('disabled taskButton')){
          $("#taskButton").removeClass('disabled taskButton')
         }
     })
       
  }

}


async editStudentProfile(){ 
  $('#imageUpload').click(async function(e){ 
  
      console.log('uploadRan')
  
    e.preventDefault()
     // Configure Cloudinary
     // with the credentials on
     // your Cloudinary dashboard
     await  $.cloudinary.config({ cloud_name: 'code4teen-tech', api_key: '373254287764286'});     
     // Initiate upload
     window.cloudinary.openUploadWidget({ cloud_name: 'code4teen-tech', upload_preset: 'candidateImage', tags: ['cgal']}, 
     async function(error, result) { 
     if(error) console.log(error);
   
     if(result){    
       console.log(result) 
       //data sent in request body 
          let avatar = {
          avatar: result[0].secure_url
            }
  
        
  
          let updateAvatar = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/updateAvatar',{ 
            method: "POST",
            body: JSON.stringify(avatar),
            headers:{
              'Content-Type':'application/JSON',
              'Accept': 'application/JSON'
            },
            mode:'cors',
            credentials:'include'  
          })
        
          .then(response => response.json())
          .then(response => { 
          
            this.setState({ 
              candidateAvatar : response.avatar[0]
            })
          })
  
  
     }
       //console.log(result);
     
   
   }.bind(this))
  
  
  
    }.bind(this))
  }


async saveEditorResponse(){
  const {match :{params}} = this.props;
  const data = {
    response:this.state.codeData,
    ref: params.taskRef
   }

  const saveResponse = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/response-api/v1/saveEditorResponse',{ 
    method: "POST",
    body: JSON.stringify(data),
    headers:{
      'Content-Type':'application/JSON',
      'Accept': 'application/JSON'
    },
    mode:'cors',
    credentials:'include'  
  })

  .then(response => response.json())
  .then(response => {


     if(response){

       ToastsStore.success(`your answer has been saved!`, 
                  2000)

     }else if(!response){ 

         
       ToastsStore.error(`check internet and re-type`, 
                  2000)

     }
    console.log('saved editor response')

  })
}


 
async retrieveCandidateDetails(){ 

    const candidate = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/candidate',{ 
      method:"POST",
      headers:{ 
        "content-type" : "application/json"
       },
     mode:"cors",
     credentials:"include"   
    })
    .then(response => response.json())

    .then(response => { 
       console.log(response)
      this.setState({
      candidateFirstName:response.firstname,
      candidateFullname : response.candidateFirstname + " " + response.lastname,
      candidateSkin:response.skin,
      candidateAvatar: response.avatar[0],
      candidateClass : response.class,
      candidateTopicName: response.topicName,
      candidateTopicDescription: response.topicDescription,
      candidateTopicVisual: response.topicVisual,
      candidateAssessmentStatus : response.assessmentStatus,
      candidateAge : response.age,
      candidateSchool: response.school,
      candidateSchoolid : response.schoolid,
      schoollogo: response.logo,
      assessmentRef:response.assessmentReference
      }) 
    }
    )

}


async retrieveTaskDetails(){ 

       const {match:{ params }} = this.props

       const data = { assessmentRefClient:this.state.assessmentRefLink }

       const taskRef = await fetch(`https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/${params.taskRef}/forward-switch-resource-assessment/${params.topicRef}/${params.assessmentRef}`, { 
         method:"POST",
         mode:"cors", 
         body: JSON.stringify(data),
         headers:{ 
            "content-type" : "application/JSON" 
         },
         credentials:"include"
       })
   const taskRefData = await taskRef.json()
   
   console.log(taskRefData)
   
   this.setState({ 
       nextTaskRef : taskRefData.nextRef,
       prevTaskRef: taskRefData.prevRef,
       assessmentRefLink: taskRefData.assessmentRef
          })

   console.log(taskRefData)
}

 async retreiveResourceIconPack(){ 
   console.log("fired")
    const {match:{params}} = this.props

    const iconPack = await fetch(`https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/${params.topicRef}/retrieveIconPack`, { 
       method:"POST",
       headers:{
        "Content-Type" : "application/json"
       },
       mode:"cors",
       credentials:"include"     
    })   

    const  recievedIconPack = await iconPack.json()

    console.log(recievedIconPack)

    this.setState({ 
     iconPack : recievedIconPack
    })
 }




render(){ 
 
  setInterval(this.timeUpdate, 1000)
 


 //handle null and active button display with boolean state
    let {match: { params } } = this.props
    console.log(params.taskRef)
    console.log(params.topicRef)
    let backwardSwitch 
    let forwardSwitch
              

          if(this.state.prevTaskRef === false){ 
            console.log ("disabled ran")
            backwardSwitch = <img src={backwardSwitchButtonDisabled} width="40"/>
          }else{
         backwardSwitch = <BackwardSwitchButton topicRef={this.state.topicRef}  prevTaskRef={this.state.prevTaskRef} assessmentRef={this.state.assessmentRefLink}/>
           }
         if (this.state.activateForwardArrow === false || this.state.nextTaskRef === false){
           forwardSwitch = <img src={switchButtonDisabled} width="40"/> 
            }else{
           forwardSwitch = <SwitchButton topicRef={this.state.topicRef} taskRef={this.state.nextTaskRef} currentProgress={this.progressUpdate} assessmentRef={this.state.assessmentRefLink} />
            }
          
      let iconPackDetails
      let skinPackDetails
      let assessmentRefDetails
      let newRef 
      let onlineFellows

      if(this.state.assessmentRef.length){
        console.log(this.state.assessmentRef)

        assessmentRefDetails = this.state.assessmentRef.map( assessmentDetails => {
          if(!assessmentDetails.result){
           return <div className="row">
            <div className="col-6"> 
                  <h6 className="body-font white-text"> {assessmentDetails.topic} </h6>
            </div>
  
            <div className="col-6 body-font"> 
                   <button className="btn btn-outline-success disabled taskButton" id={assessmentDetails.ref}> See results </button>
                   </div>
  
          </div>
          }

          if(assessmentDetails.result){
             console.log('built')
            return <div className="row">
             <div className="col-6"> 
                   <h6 className="body-font white-text"> {assessmentDetails.topic} </h6>
             </div>
   
             <div className="col-6 body-font"> 
                    <button className="btn btn-outline-success results" onClick={this.resultScreen} id={assessmentDetails.ref}> See results </button>
                    </div>
   
           </div>
           }
   
        }
         
        )
      }


      if(this.state.iconPack.length){ 
        console.log(this.state.iconPack)
             iconPackDetails = this.state.iconPack.map(iconDetails => 

          <li className="standard-padding-tb text-center"> 
             <figure> 
             <img src={iconDetails.resourceIcon} width="80px"/>
             <figcaption className='header-font white-text'>
             {iconDetails.ref}
             </figcaption> 
             </figure> 
             </li>
       )
         
      }

           if(this.state.skinPack.length){ 
     
          skinPackDetails = this.state.skinPack.map(skinDetails => 
          
           
          <div className="col-md-4 text-center"> 
             <figure> 
             <img src={skinDetails.skinLink} onClick={this.skinUpdate} value={skinDetails.ref} id={skinDetails.ref} width="250px"/>
             <figcaption className='header-font white-text'>
             {skinDetails.ref}
             </figcaption> 
             </figure> 
             </div>
       )
         
      }


      if(this.state.onlineCandidates.length){ 

          onlineFellows = this.state.onlineCandidates.map(candidateDetails => 

            <div className="col-md-2 text-center"> 
             <figure> 
             <img src={candidateDetails.avatar} width="150px"/>
             <figcaption className='header-font white-text'>
             {candidateDetails.firstname}
             </figcaption> 
             </figure> 
             </div>

          )

      }

   
 return(

      <div className="">
        <header> 
         <Nav menuScreen={this.skinMenu} profileMenu={this.profileMenu} exploreScreen={this.exploreScreen} />
        </header>
         <div  className="nav-clear-space-screen" >
           <div id="full-width"  className="skin-actual full-width">
                 <img src={this.state.candidateSkin} id="skin-layer" width="100%" height="100%"  />
           </div>



            

           <div className="app-screen-padding-1 top-right-curve bottom-right-curve skin padding-off" id="explore-screen"> 
        <div className="row left-side-curve top-right-curve bottom-right-curve"  id="labBoxStyle"> 

        <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background" > 
               
          <div className="row standard-padding-top-1 standard-margin-top fixed-top">
          <div className="col-6 col-md-5 offset-md-1">
          <img id="" src={this.state.schoollogo} width="70"/>
           </div> 
           <div className="col-5">
             <h5 className="header-font text-right white-text"> Close Explore <span> <img src={forwardSwitchButton} onClick={this.exploreClose} width='35' /> </span> </h5>
           </div> 
           </div>
           <div className="row standard-padding-top-1">
           <div className="col-12 text-center">
                <img id="candidateImage" src={this.state.candidateAvatar} width="100"/>
             </div>

             <div className="col-12 text-center"> 
             <button className="btn btn-danger" id="imageUpload"> Change Photo </button>
             </div>

             <div className="col-12 col-md-4 mx-auto"> 
                <div className="row text-center"> 
                 <div className="col-12"> 
                    <h3 className="accent-font white-text"> {this.state.candidateFullname} </h3>
                  </div>
                  <div className="col-12 col-md-4"> 
                    <h6 className="body-font white-text"> Age:{this.state.candidateAge} </h6>
                  </div>

                  <div className="col-12 col-md-4"> 
              <h6 className="body-font white-text"> Grade:{this.state.candidateClass} </h6>
                  </div>

                  <div className="col-12 col-md-4"> 
                  <Link to={this.state.assessmentLink}>
              <button className="btn btn-outline-danger" id="taskButton"> Pending Task </button>
              </Link>
                  </div>

                </div>

      <hr className="white-border full-width" />

             </div> 




          </div> 
    <div className="row standard-margin-top">
             <div className="col-12 col-md-4 offset-md-1 text-center">
            <h6 className="accent-font white-text"> You are currently learning </h6>
            <img src={this.state.candidateTopicVisual} width="100%"/>
            <h4 className="header-font white-text"> {this.state.candidateTopicName} </h4>
            <h6 className="body-font white-text"> {this.state.candidateTopicDescription} </h6>
             </div>
             <div className="col-12 offset-md-1 col-md-6"> 
          <h6 className="accent-font white-text"> Your assessments for {this.state.candidateTopicName} </h6>
             {assessmentRefDetails}



             </div>
           


               
             </div>

        </div>

        </div>
    
       </div>


       <div className="app-screen-padding-1 top-right-curve bottom-right-curve skin padding-off" id="result-screen"> 
        <div className="row left-side-curve top-right-curve bottom-right-curve"  id="labBoxStyle"> 

        <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background" > 
               
          <div className="row">
           <div className="col-12 standard-padding-tb"> 
            <h2 className="white-text accent-font text-center"> Here's how you performed {this.state.candidateFirstName} </h2>
           </div> 
           <div className="col-12 col-md-5 mx-auto text-center">
         <h4 className="body-font white-text"> {this.state.candidateTopicName} - {this.state.newAssessmentRef} </h4>
         </div>
         <div className="col-10 mx-auto">
         <img src={this.state.candidateTopicVisual} width="100%"/>
         </div>
       
         <div className="col-12 col-md-4 text-center">

           <h4 className="accent-font white-text"> Assessment Score</h4>
           <h1 className="body-font white-text">  
             {this.state.assessmentGrade}
                     
           </h1>

         </div>

         <div className="col-12 col-md-4 text-center">

           <h4 className="accent-font white-text"> Current Topic Score</h4>
           <h1 className="body-font white-text"> 
             {this.state.currentTopicGrade}
            </h1>

         </div>

         <div className="col-12 col-md-4 text-center">

            <h4 className="accent-font white-text"> Total Topic Score</h4>
            <h1 className="body-font white-text"> 
              {this.state.totalTopicGrade}
             </h1>

         </div>

           <div className="col-12">
             <h5 className="header-font text-right white-text">  <span> <img src={forwardSwitchButton} onClick={this.resultClose} width='35' /> </span> back to explore </h5>
           </div> 

          </div> 
    

        </div>

        </div>
    
       </div>



      <div className="app-screen-padding-1 top-right-curve bottom-right-curve skin padding-off" id="fellows-screen"> 
            <div className="row left-side-curve top-right-curve bottom-right-curve"  id="labBoxStyle"> 

        <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background" > 
               
          <div className="row">
           <div className="col-12 standard-padding-tb"> 
            <h2 className="white-text accent-font text-center"> See whose coding in your class </h2>
           </div> 
           {onlineFellows}
           <div className="col-12">
             <h5 className="header-font text-right white-text"> close skin menu <span> <img src={forwardSwitchButton} onClick={this.profileClose} width='35' /> </span> </h5>
           </div> 

          </div> 
    

        </div>

        </div>
    
       </div>

            <div className="app-screen-padding-1 top-right-curve bottom-right-curve skin padding-off" id="menu-screen"> 
        <div className="row left-side-curve top-right-curve bottom-right-curve"  id="labBoxStyle"> 

        <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background" > 
               
          <div className="row">
           <div className="col-12 standard-padding-tb"> 
            <h2 className="white-text accent-font text-center"> Hello, Select a skin for your tablet </h2>
           </div> 
           {skinPackDetails}
           <div className="col-12">
             <h5 className="header-font text-right white-text"> save and close skin menu <span> <img src={forwardSwitchButton} onClick={this.menuClose} width='35' /> </span> </h5>
           </div> 

          </div> 
    

        </div>

        </div>
    
       </div>


                     <div className="container top-right-curve bottom-right-curve skin padding-off" id="sleep-screen"> 
        <div className="row left-side-curve top-right-curve bottom-right-curve"  id="labBoxStyle"> 

        <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background" > 

          <figure className="text-center standard-padding-tb nav-clear-space-screen"> 
              <h6 className="body-font text-center white-text" id="currentTime">  </h6>
              <img className="standard-margin-top-quiz" src={atclogo} width='200' />
              <figcaption className="standard-margin-top-quiz">
                 <h5 className="header-font text-center white-text"> Wake Andrew Up <span> <img src={forwardSwitchButton} onClick={this.wakeScreen} width='35' /> </span> </h5>
              </figcaption> 
          </figure>
    

        </div>

        </div>
    
       </div>



           <div className="app-screen-margin top-right-curve bottom-right-curve skin padding-off" id="active-screen"> 
        <div className="row left-side-curve top-right-curve bottom-right-curve" id="labBoxStyleClass"> 
        <div className="col-md-2 blue-background-2 top-left-curve bottom-left-curve" id="sidebar-properties"> 
         <div className="row"> 
         <div className="col-12 green-background top-left-curve standard-padding-tb sub-menu-style"> 
                 <p className="white-text text-center margin-off body-font"> We are learning </p> 
         </div>
          <div className="col-12 height-test-minus sub-menu-layout standard-padding-tb ">
           <ul className="padding-off"> 
            {iconPackDetails}
         </ul>
           
          </div>
          <div className="col-12 standard-padding-tb text-center green-background"> 

              {backwardSwitch}
              {forwardSwitch}  
          </div>
         </div>         
        </div>

        <div className="col-md-10 padding-off " id="explanation"> 

        {this.props.children}
         <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} timer={10000}/>
        <Route path={`/lab/:topicRef/assessments/:taskRef/category/:assessmentRef`} render={ (props) => <Assessments {...props} dynamicWidth={this.state.width} dynamicHeight={this.state.height} byteStateValue={this.byteStateUpdate} onTaskStatusUpdate={this.taskStatusUpdate} onHtmlChange={this.htmlStateUpdate}  onBookNameUpdate={this.bookNameUpdate} onAssessmentTagUpdate={this.assessmentTagUpdate} onAssessmentLinkUpdate={this.assessmentLinkUpdate} progressUpdate={this.progressUpdate} quizOnChange={this.quizAssessmentResponseUpdate}/> } />
       


     <Modal className="white-background-trans" show={this.state.addManualModalShow} animation={false}>
           <Modal.Header>
          <Modal.Title> Welcome to Assessments </Modal.Title>
        </Modal.Header>
        <Modal.Body className="padding-off">

       
         <AwesomeSlider>

          
        
          
        
           <div data-src={graded} />


           <div data-src={update} />

           <div data-src={submit} />
 
        


         </AwesomeSlider>
      

          </Modal.Body>
        <Modal.Footer className="standard-padding-tb">

          <button id="" onClick={()=> this.setState({addManualModalShow:false})} className="btn btn-success white-text"> Close </button>
       
        </Modal.Footer>
      </Modal>

        </div>

        </div>
    
       </div>
       </div>
       </div>

     	)   
 }
}

export default lab1;